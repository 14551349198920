.box1 {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.box2 {
    height: 100%;
    width: 100%;
    padding-top: 10px;
    overflow: auto;
    /* padding-right: 20px; */
}