/* .table-wrapper {
    position:relative;
  } */


/* .table-wrapper table{
    width: 600px;
} */

.reports {
    overflow: auto;
    margin-top: 20px;
    border-left: solid 4px #20204d;
    border-top: solid 4px #20204d;
    border-radius: 5px;
}

/* .reports table, */
.reports th,
.reports td {
    /* border: solid 1px #575757; */
    border-right: solid 4px #20204d;
    border-bottom: solid 4px #20204d;
    padding: 5px;
    table-layout: fixed;
    /* width: 50px; */
    overflow: hidden;
}

.reports table {
    /* width: 100%; */
    border-collapse: collapse;
    caption-side: bottom;
}

.reports caption {
    font-size: 16px;
    font-weight: bold;
}

.reports th {
    font-size: 15px;
    text-align: left;
}

.reports .typename:hover {
    cursor: pointer;
    /* background-color: rgba(0, 0, 0, 0.4) !important; */
}

.reports .avl-num {
    color: #4BB543;
}

.reports .bkd-num {
    color: #ff9800;
}

.reports .avl {
    /* background-color: #4BB543; */
    border-bottom: 4px solid #4BB543;
    /* background: linear-gradient(0deg, #4BB543 30%, transparent 30%); */
}

.reports .bkd {
    font-size: 13px;
    color: black;
    /* background-color: #ff9800; */
    /* background: linear-gradient(0deg, #ff9800 30%, transparent 30%); */
}

.reports .hold {
    font-size: 13px;
    color: black;
    /* background-color: #525252; */
    border-bottom: 4px solid #525252;
}
.reports .unblock-hold {
    border-bottom: 4px solid #525252;
}

.reports .phs {
    font-size: 13px;
    color: black;
    /* background-color: #ffe600; */
}

.reports .direct {
    background-color: #8cf2ff;
}

.reports .ota {
    background-color: #dbdbdb;
}

.reports .agent {
    color: black;
    background-color: #aaabff;
}

.reports .other {
    color: white;
    mix-blend-mode: difference;
}

/* .reports .avl-month {
    background-color: #4BB543;
}

.reports .bkd-month {
    font-size: 13px;
    background-color: #ff9800;
} */

.reports .phs:hover,
.reports .hold:hover,
.reports .bkd:hover {
    background-color: rgba(28, 102, 84, 0.212);
    cursor: pointer;
    user-select: none;
}